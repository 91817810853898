
  import { Component, Vue } from "vue-property-decorator";

  @Component
  export default class Testimonials extends Vue {
    testimonials = [
      {
        id: -1,
        text:
          "I sought out Dr. Carnaby due to her reputation and began her program at the end of August. She then began the treatment that would change my life.",
        source: "Patient",
      },
      {
        id: -2,
        text:
          "Attention all dysphagia therapists. I can't recommend this course enough! Highly valuable in my dysphagia practice and great outcomes.",
        source: "Professional",
      },
      {
        id: -3,
        text:
          "I can honestly say that the treatment and care I received during my time in Florida genuinely changed and I might even say, saved my life.",
        source: "Patient",
      },
      {
        id: -4,
        text:
          "Thank you for offering this virtually. I would not be able to avail of this incredible education otherwise and I am truly so glad I took the course. I am eager to put it into my practice",
        source: "Professional",
      },
      {
        id: -5,
        text:
          " I gained more in a few weeks than in the entire 18 months I was previously treated. The biggest achievement and the most amazing and breathtaking moment was my swallow returning.",
        source: "Patient",
      },
      {
        id: -6,
        text:
          "I think it is one of the best treatments for swallowing out there. The success I have had using it, is amazing. HIGHLY recommend taking the course. The Philosophy behind the science is perfect.",
        source: "Professional",
      },
      {
        id: -7,
        text:
          "I attended an original McNeill Dyphagia course in 2007 before they ever offered certification. I became certified years later, when it was first offered, just for my resume as I was already using it. I personally believe it is worth the cost due to the outcomes achieved.",
        source: "Professional",
      },
      {
        id: -8,
        text:
          "It was so much worth it for reframing my mindset with dysphagia care to a more couragous & functional perspective",
        source: "Professional",
      },
    ];
  }
